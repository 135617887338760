import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { slugify } from '../utils';

const StyledShopLocations = styled.ul`
  margin: 26px 0;

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

const StyledItem = styled.li`
  margin-top: 26px;

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledHeading = styled.h3`
  margin-bottom: 8px;
  color: ${brandColours.primary};
  font-family: ${brandFonts.secondary};
  ${fontSize(20)};

  ${minBreakpointQuery.large`
    margin-bottom: 12px;
    ${fontSize(22)};
  `}
`;

const StyledGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  ${minBreakpointQuery.large`
    gap: 40px;
  `}
`;

const StyledText = styled.p`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};
  line-height: 1.5;
  white-space: break-spaces;

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}

  span {
    display: block;
    color: ${brandColours.primary};
  }
`;

const StyledLink = styled.a`
  ${fontSize(13)};

  ${minBreakpointQuery.large`
    ${fontSize(15)};
    text-decoration: underline;
  `}
`;

const ShopLocations = () => {
  const {
    datoCmsContactInformation: { shopLocations },
  } = useStaticQuery(graphql`
    query ShopLocationsQuery {
      datoCmsContactInformation {
        shopLocations {
          name
          address
          openingHours
        }
      }
    }
  `);

  return (
    <StyledShopLocations>
      {shopLocations.map(({ name, address, openingHours }) => (
        <StyledItem>
          <StyledHeading>{name}</StyledHeading>
          <StyledGroup>
            <StyledText>
              {address}
              <br />
              <StyledLink
                href={`https://www.google.co.uk/maps/dir//${slugify(address)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                (Get Directions)
              </StyledLink>
            </StyledText>
            <StyledText>
              <span>Opening Hours:</span>
              {openingHours}
            </StyledText>
          </StyledGroup>
        </StyledItem>
      ))}
    </StyledShopLocations>
  );
};

export default ShopLocations;
