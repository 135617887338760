import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Container, HtmlContent, Grid, Heading } from '../components/ui';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContactForm from '../components/ContactForm';
import ShopLocations from '../components/ShopLocations';

const ContactPage = () => {
  const {
    datoCmsContact: { seoMetaTags, title, content },
  } = useStaticQuery(graphql`
    query ContactPageQuery {
      datoCmsContact {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        content
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <PageHeader heading={title} />
        <Container>
          <HtmlContent html={content} center={true} />
          <Grid>
            <ContactForm isContactPage={true} />
            <Heading hide={true}>Shop Locations</Heading>
            <ShopLocations />
          </Grid>
        </Container>
      </main>
    </Layout>
  );
};

export default ContactPage;
