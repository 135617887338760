import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  fontSize,
  commonFormElementStyles,
  commonFormButtonStyles,
  visuallyHidden,
} from '../styles';
import { Container, Heading } from './ui';
import { slugify, dataLayer } from '../utils';

const StyledContactForm = styled.section`
  margin: 10px 0;

  ${minBreakpointQuery.large`
    margin-top: 20px;
    margin-bottom: 20px;
  `}

  ${({ isContactPage }) => {
    if (!isContactPage) {
      return css`
        ${sectionMargins()};
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  ${({ isContactPage }) => {
    if (!isContactPage) {
      return css`
        max-width: 800px;
      `;
    } else {
      return css`
        padding-right: 0;
        padding-left: 0;
      `;
    }
  }}
`;

const StyledHeading = styled(Heading)``;

const StyledText = styled.p`
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}
`;

const StyledForm = styled.form``;

const StyledLabel = styled.label`
  display: block;
  margin: 20px 0;

  ${({ hide }) => {
    if (hide) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledLabelText = styled.span`
  ${visuallyHidden()};
`;

const StyledInput = styled.input`
  ${commonFormElementStyles()};
`;

const StyledTextArea = styled.textarea`
  ${commonFormElementStyles()};
  height: 180px;
  min-height: 80px;
  max-height: 280px;
  resize: vertical;
`;

const StyledButton = styled.button`
  ${commonFormButtonStyles()};

  ${minBreakpointQuery.tiny`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const ContactForm = ({
  heading,
  text,
  name = 'Contact Form',
  isContactPage,
}) => {
  const formName = slugify(name);

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById(formName);
    const formData = new FormData(myForm);

    fetch('/api/sendemail', {
      method: 'POST',
      body: JSON.stringify(new URLSearchParams(formData).toString()),
    })
      .then(() => {
        dataLayer('contactFormSubmit');
        window.location.href = '/thanks/';
      })
      .catch(error => alert(error));
  };
  return (
    <StyledContactForm isContactPage={isContactPage}>
      <StyledContainer isContactPage={isContactPage}>
        {heading && <StyledHeading>{heading}</StyledHeading>}
        {text && <StyledText>{text}</StyledText>}
        <StyledForm
          id={formName}
          name={formName}
          method="POST"
          onSubmit={e => {
            handleSubmit(e);
          }}
        >
          <input type="hidden" name="form-name" value={formName} />
          <StyledLabel>
            <StyledLabelText>Name</StyledLabelText>
            <StyledInput type="text" name="name" placeholder="Name" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Email</StyledLabelText>
            <StyledInput
              type="email"
              name="email"
              placeholder="Email"
              required
            />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Subject</StyledLabelText>
            <StyledInput type="text" name="subject" placeholder="Subject" />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Message</StyledLabelText>
            <StyledTextArea name="message" placeholder="Message" required />
          </StyledLabel>
          <StyledLabel hide={true}>
            <StyledLabelText>Question</StyledLabelText>
            <StyledInput name="question" />
          </StyledLabel>
          <StyledButton>Send</StyledButton>
        </StyledForm>
      </StyledContainer>
    </StyledContactForm>
  );
};

export default ContactForm;
